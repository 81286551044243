import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
        @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
        body {
           margin: 0;
           padding: 0;
        }

        *{
                box-sizing: border-box;
                font-family: 'Roboto', sans-serif;;
        }
    
`;
